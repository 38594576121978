import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { fetcher } from 'utils';

import { SCHEDULE_MANAGE_BASE_URL } from './configs';
import { handleGenerateScheduleDataObject, sortUpcomingDate } from './utils';

const getAllScheduleUrl = `${SCHEDULE_MANAGE_BASE_URL}/get-all-schedule`;

export const useMutateScheduleData = () => {
  const { mutate } = useSWRConfig();

  return useCallback(() => {
    mutate(getAllScheduleUrl);
  }, [mutate]);
};

export const useGetAllSchedule = () => {
  const { data, mutate, isValidating, error } = useSWR(getAllScheduleUrl, fetcher);

  return {
    errorMessage: error,
    isLoading: isValidating,
    mutate,
    data: data?.data?.length ? [...sortUpcomingDate(data.data).map((scheduleItem) => handleGenerateScheduleDataObject(scheduleItem))] : []
  };
};
