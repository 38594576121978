import { any, string } from 'prop-types';
import { forwardRef } from 'react';

export const Input = forwardRef(({ inputIcon, className = '', ...props }, ref) => {
  return (
    <div className={`relative input-validate ${className}`}>
      <div className='absolute no-underline h-[18px] top-6 left-6 select-none flex items-center'>{inputIcon}</div>
      <div className='input-validate__icon-error'>
        <svg className='icon' viewBox='0 0 10 10'>
          <path d='M5.80084 6.36083L6.1325 0H3.88L4.2125 6.36083H5.80084Z'></path>
          <path d='M5 10C5.69036 10 6.25 9.44036 6.25 8.75C6.25 8.05964 5.69036 7.5 5 7.5C4.30964 7.5 3.75 8.05964 3.75 8.75C3.75 9.44036 4.30964 10 5 10Z'></path>
        </svg>
      </div>
      <div className='input-validate__icon-success'>
        <svg className='icon' viewBox='0 0 10 10'>
          <path d='M8.57751 1.07768L3.33335 6.32184L1.42251 4.41101C1.26535 4.25921 1.05484 4.17522 0.836346 4.17711C0.617849 4.17901 0.408838 4.26665 0.254332 4.42116C0.0998249 4.57567 0.0121841 4.78468 0.0102854 5.00318C0.00838673 5.22167 0.0923821 5.43217 0.24418 5.58934L2.74418 8.08934C2.90045 8.24557 3.11238 8.33333 3.33335 8.33333C3.55432 8.33333 3.76624 8.24557 3.92251 8.08934L9.75585 2.25601C9.90765 2.09884 9.99164 1.88834 9.98974 1.66984C9.98784 1.45134 9.9002 1.24233 9.7457 1.08783C9.59119 0.933321 9.38218 0.84568 9.16368 0.843782C8.94518 0.841883 8.73468 0.925878 8.57751 1.07768Z'></path>
        </svg>
        <svg className='lines' viewBox='0 0 40 40'>
          <path d='M20 8V2'></path>
          <path d='M32 20H38'></path>
          <path d='M20 32V38'></path>
          <path d='M8 20H2'></path>
          <path d='M10.5 10.5L6.5 6.5'></path>
          <path d='M29.5 10.5L33.5 6.5'></path>
          <path d='M10.5 29.5L6.5 33.5'></path>
          <path d='M29.5 29.5L33.5 33.5'></path>
        </svg>
      </div>
      <input
        {...props}
        ref={ref}
        className='placeholder-gray-400 w-full px-6 py-[22px] border-2 border-gray-300 mb-0 align-middle bg-white transition appearance-none font-light focus:outline-none focus:bg-gray-50 resize-none pr-15 pl-[48px] h-[64px] rounded-lg input-item'
      />
    </div>
  );
});

Input.displayName = 'Input';

Input.propTypes = {
  inputIcon: any,
  className: string
};
