import './Loading.scss';

import { bool, string } from 'prop-types';
import { memo, useMemo } from 'react';

const Loading = ({ classNames = '', isLoadingMask = false }) => {
  const loadingClassNames = useMemo(() => {
    const classNamesTemp = ['w-screen h-screen flex justify-center items-center loading-comp', classNames];
    if (isLoadingMask) classNamesTemp.push('fixed left-0 top-0 pointer-events-none !m-0 bg-white/80');

    return classNamesTemp;
  }, [isLoadingMask, classNames]);

  return (
    <div className={loadingClassNames.join(' ')}>
      <div className='lds-ellipsis'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  classNames: string,
  isLoadingMask: bool
};

export default memo(Loading);
