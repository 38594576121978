import { any, string } from 'prop-types';
import { forwardRef } from 'react';

const Button = forwardRef(({ label, className = '', ...props }, ref) => {
  return (
    <button
      {...props}
      ref={ref}
      className={`py-4 px-6 rounded-md mx-auto max-w-md block cursor-pointer border-none w-full font-semibold box-border button transition text-center transform active:scale-90 text-base leading-normal bg-pacific-blue text-white hover:bg-blue-400 disabled:cursor-not-allowed disabled:bg-[#91979d] disabled:hover:bg-[#878a8d] ${className}`}
    >
      <h3>{label}</h3>
    </button>
  );
});

Button.displayName = 'Button';

Button.propTypes = {
  label: any,
  className: string
};

export default Button;
