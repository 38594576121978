import 'assets/scss/index.scss';
import 'aos/dist/aos.css';

import AOS from 'aos';
import Loading from 'components/loading';
import { AuthProvider } from 'context';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

AOS.init();

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <React.Suspense fallback={<Loading />}>
          <App />
        </React.Suspense>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
