import { any, string } from 'prop-types';

const SectionLayout = ({ className = '', children }) => {
  return (
    <section className={`w-full flex justify-center items-center ${className}`}>
      <div className='w-full sm:max-w-xl md:max-w-3xl lg:max-w-4xl section-layout__container'>{children}</div>
    </section>
  );
};

SectionLayout.propTypes = {
  children: any,
  className: string
};

export default SectionLayout;
