import { useCallback } from 'react';
import api from 'utils/api';

import { useMutateGetSubmission } from '../surveyForm';
import { SEND_MEETING_SCHEDULE_BASE_URL } from './configs';
import { useGetAllMeetingSchedule } from './useGetAllMeetingSchedule';

export const useSendMeetingSchedule = () => {
  const { mutate } = useGetAllMeetingSchedule();
  const mutateGetSubmissionCase = useMutateGetSubmission();

  return useCallback(
    async (data) => {
      const { email, fullName, submissionId, scheduleDate, scheduleTime, location } = data;

      const sendMeetingScheduleResponse = await api.post(`${SEND_MEETING_SCHEDULE_BASE_URL}/create-meeting-schedule`, {
        email: email,
        fullName: fullName,
        submissionId: submissionId,
        scheduleDate: scheduleDate,
        scheduleTime: scheduleTime,
        location: location
      });

      mutate();

      mutateGetSubmissionCase();

      return sendMeetingScheduleResponse;
    },
    [mutate, mutateGetSubmissionCase]
  );
};
