import Title from 'components/title';
import { string } from 'prop-types';

export const QuestionTitle = ({ title, subtitle, titleClassName = '' }) => {
  return (
    <div className={subtitle ? 'pb-6' : ''}>
      <Title title={title} />
      <p data-aos='fade-up' className='text-center'>
        {subtitle}
      </p>
    </div>
  );
};

QuestionTitle.propTypes = {
  title: string,
  subtitle: string,
  titleClassName: string
};
