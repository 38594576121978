export const SURVEY_PROGRESS_PATH = ['position-interested', 'more-interested-in', 'contact-info', 'submit-success'];

export const ANSWER_QUESTION_1_KEY = 'positionInterested';

export const ANSWER_QUESTION_2_KEY = 'moreInterestedIn';

export const SURVEY_FORM_ANSWER_KEY = {
  'position-interested': ANSWER_QUESTION_1_KEY,
  'more-interested-in': ANSWER_QUESTION_2_KEY
};

export const SURVEY_FORM_ROOT_PATH = '/survey-form';

export const USER_SKIP_ANSWER_THE_FORM_QUESTION = 'USER_SKIP_ANSWER_THE_FORM_QUESTION';
