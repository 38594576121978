import { any, string } from 'prop-types';

const ImageLayout = ({ children, className = '' }) => {
  return (
    <div data-aos='fade-up' className={`py-6 sm:py-10 sm:px-20 ${className}`}>
      {children}
    </div>
  );
};

ImageLayout.propTypes = {
  children: any,
  className: string
};

export default ImageLayout;
