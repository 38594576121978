import { useCallback } from 'react';
import api from 'utils/api';

import { SEMINAR_REGISTRATION_BASE_URL } from './configs';
import { useMutateGetSeminar } from './useGetAllSeminar';

export const useDeleteSeminar = () => {
  const mutateCase = useMutateGetSeminar();

  return useCallback(
    async (seminarId) => {
      const deleteSeminarResponse = await api.delete(`${SEMINAR_REGISTRATION_BASE_URL}/delete-registration/${seminarId}`);

      mutateCase();

      return deleteSeminarResponse;
    },
    [mutateCase]
  );
};
