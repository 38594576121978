import { SURVEY_FORM_ROOT_PATH, SURVEY_PROGRESS_PATH } from 'pages/surveyForm/configs';
import ContactInfoForm from 'pages/surveyForm/ContactInfoForm';
import Question1 from 'pages/surveyForm/Question1';
import Question2 from 'pages/surveyForm/Question2';
import { lazy } from 'react';

const SurveyFormPageRoot = lazy(() => import('pages/surveyForm'));
const SubmitSuccess = lazy(() => import('pages/surveyForm/SubmitSuccess'));
const AdminPageRoot = lazy(() => import('pages/admin'));
const SubmissionListing = lazy(() => import('pages/admin/Submission/SubmissionListing'));
const SubmissionDetail = lazy(() => import('pages/admin/Submission/SubmissionDetail'));
const UserInfoListing = lazy(() => import('pages/admin/User/UserInfoListing'));
const CreateNewAccountForm = lazy(() => import('pages/admin/User/CreateNewAccountForm'));
const LoginPage = lazy(() => import('pages/login'));
const ResetPassword = lazy(() => import('pages/resetPassword'));
const PageNotFound = lazy(() => import('pages/pageNotFound'));
const SeminarRegistrationListing = lazy(() => import('pages/admin/SeminarRegistration/SeminarRegistrationListing'));
const SeminarRegistrationDetail = lazy(() => import('pages/admin/SeminarRegistration/SeminarRegistrationDetail'));
const LandingPageListing = lazy(() => import('pages/admin/LandingPage/LandingPageListing'));
const CreateLandingPage = lazy(() => import('pages/admin/LandingPage/CreateLandingPage'));
const LandingPageDetail = lazy(() => import('pages/admin/LandingPage/LandingPageDetail'));
const UpdateLandingPage = lazy(() => import('pages/admin/LandingPage/UpdateLandingPage'));
const ScheduleManage = lazy(() => import('pages/admin/ScheduleManage'));
const ScheduleDetailPage = lazy(() => import('pages/admin/ScheduleManage/ScheduleDetailPage'));
// const SentEmail = lazy(() => import('pages/admin/SentEmail'));
// const SentEmailDetail = lazy(() => import('pages/admin/SentEmail/SentEmailDetail'));

export const ADMIN_BASE_URL = '/admin';

export const routes = [
  {
    path: SURVEY_FORM_ROOT_PATH,
    element: <SurveyFormPageRoot />,
    isPrivate: false,
    children: [
      {
        path: SURVEY_PROGRESS_PATH[0],
        element: <Question1 />
      },
      {
        path: SURVEY_PROGRESS_PATH[1],
        element: <Question2 />
      },
      {
        path: SURVEY_PROGRESS_PATH[2],
        element: <ContactInfoForm />
      },
      {
        path: SURVEY_PROGRESS_PATH[3],
        element: <SubmitSuccess />
      }
    ]
  },
  {
    path: ADMIN_BASE_URL,
    element: <AdminPageRoot />,
    isPrivate: true,
    children: [
      {
        path: 'submission-listing',
        element: <SubmissionListing />
      },
      {
        path: 'submission-detail/:submissionId',
        element: <SubmissionDetail />
      },
      {
        path: 'user-manage',
        element: <UserInfoListing />
      },
      {
        path: 'create-new-account',
        element: <CreateNewAccountForm />
      },
      {
        path: 'seminar-registration',
        element: <SeminarRegistrationListing />
      },
      {
        path: 'registration-detail/:registrationId',
        element: <SeminarRegistrationDetail />
      },
      {
        path: 'landing-page-manage',
        element: <LandingPageListing />
      },
      {
        path: 'create-landing-page',
        element: <CreateLandingPage />
      },
      {
        path: 'landing-page-detail/:landingPageSlug',
        element: <LandingPageDetail />
      },
      {
        path: 'update-landing-page/:landingPageSlug',
        element: <UpdateLandingPage />
      },
      {
        path: 'schedule-manage',
        element: <ScheduleManage />
      },
      {
        path: 'schedule-manage/schedule-detail',
        element: <ScheduleDetailPage />
      } // {
      //   path: 'sent-mail',
      //   element: <SentEmail />
      // },
      // {
      //   path: 'sent-mail/:emailId',
      //   element: <SentEmailDetail />
      // }
    ]
  },
  {
    path: '/login',
    element: <LoginPage />,
    isPrivate: false,
    children: []
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    isPrivate: false,
    children: []
  },
  {
    path: '*',
    element: <PageNotFound />,
    isPrivate: false,
    children: []
  }
];
