export const handleValidateInputStyle = (formik, inputName) => {
  if (formik.touched?.[`${inputName}`]) {
    if (formik.errors?.[`${inputName}`]) {
      return 'invalid-input';
    } else {
      return 'valid-input';
    }
  }

  return '';
};
