import { useCallback } from 'react';
import api from 'utils/api';

import { SURVEY_FORM_BASE_URL } from './configs';
import { useMutateGetSubmission } from './useGetAllSubmission';

export const useRemoveSubmission = () => {
  const mutateCase = useMutateGetSubmission();

  return useCallback(
    async (submissionId) => {
      const removeSubmissionResponse = await api.delete(`${SURVEY_FORM_BASE_URL}/delete-submission/${submissionId}`);

      mutateCase();

      return removeSubmissionResponse;
    },
    [mutateCase]
  );
};
