import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { fetcher } from 'utils';

import { SEND_MEETING_SCHEDULE_BASE_URL } from './configs';
import { handleGenerateSentMeetingScheduleEmailObject } from './utils';

export const getAllSentMeetingEmailUrl = `${SEND_MEETING_SCHEDULE_BASE_URL}/get-all-sent-email`;

export const useMutateMeetingScheduleData = () => {
  const { mutate } = useSWRConfig();

  return useCallback(() => {
    mutate(getAllSentMeetingEmailUrl);
  }, [mutate]);
};

export const useGetAllMeetingSchedule = () => {
  const { data, mutate, isValidating, error } = useSWR(getAllSentMeetingEmailUrl, fetcher);

  return {
    errorMessage: error,
    isLoading: isValidating,
    mutate,
    data: data?.data?.length ? [...data.data.map((emailItem) => handleGenerateSentMeetingScheduleEmailObject(emailItem))] : []
  };
};
