import { arrayOf, bool, string } from 'prop-types';

import ImageResponsive from '../imageResponsive';

const SectionDetailGridLayout = ({ data, icon, isBoldText = false }) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-4 sm:gap-8 py-6 md:p-6'>
      {data.map((item, index) => (
        <div data-aos='fade-up' data-aos-duration='400' key={index} className='w-full flex justify-start items-center space-x-6'>
          <div className='max-w-[34px] w-full'>
            {icon ? (
              <div className='pb-100% relative w-full'>
                <ImageResponsive src={icon} alt={'section-detail-icon'} />
              </div>
            ) : (
              <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M17 30.6C20.607 30.6 24.0662 29.1671 26.6167 26.6167C29.1672 24.0662 30.6 20.6069 30.6 17C30.6 13.3931 29.1672 9.93384 26.6167 7.38335C24.0662 4.83285 20.607 3.4 17 3.4C13.3931 3.4 9.93387 4.83285 7.38337 7.38335C4.83288 9.93384 3.40002 13.3931 3.40002 17C3.40002 20.6069 4.83288 24.0662 7.38337 26.6167C9.93387 29.1671 13.3931 30.6 17 30.6ZM23.3019 14.8019C23.6116 14.4813 23.7829 14.0519 23.7791 13.6061C23.7752 13.1604 23.5964 12.734 23.2812 12.4188C22.966 12.1036 22.5396 11.9248 22.0939 11.921C21.6482 11.9171 21.2187 12.0884 20.8981 12.3981L15.3 17.9962L13.1019 15.7981C12.7813 15.4884 12.3519 15.3171 11.9061 15.321C11.4604 15.3248 11.034 15.5036 10.7188 15.8188C10.4036 16.134 10.2249 16.5604 10.221 17.0061C10.2171 17.4519 10.3885 17.8813 10.6981 18.2019L14.0981 21.6019C14.4169 21.9206 14.8492 22.0996 15.3 22.0996C15.7508 22.0996 16.1831 21.9206 16.5019 21.6019L23.3019 14.8019Z'
                  fill='#10A9C3'
                />
              </svg>
            )}
          </div>
          <p className={isBoldText ? 'font-bold' : ''}>{item}</p>
        </div>
      ))}
    </div>
  );
};

SectionDetailGridLayout.propTypes = {
  data: arrayOf(string),
  icon: string,
  isBoldText: bool
};

export default SectionDetailGridLayout;
