import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllLandingPageService } from 'services';

export const useFetchClientRoutes = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [landingPageData, setLandingPageData] = useState([]);
  const [isFetchingClientRoutes, setIsFetchingClientRoutes] = useState(false);

  useEffect(() => {
    if (!landingPageData.length && !pathname.includes('/admin')) {
      setIsFetchingClientRoutes(true);

      getAllLandingPageService()
        .then((response) => {
          setLandingPageData(response);
        })
        .catch((error) => error && navigate('/page-not-found'))
        .finally(() => setIsFetchingClientRoutes(false));
    }
  }, [landingPageData]);

  return { landingPageData, isFetchingClientRoutes };
};
