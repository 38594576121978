import { string } from 'prop-types';
import { useState } from 'react';

export const Checkbox = ({ className = '', ...props }) => {
  const [isCheck, setIsCheck] = useState(false);

  return (
    <div className={`relative input-validate validate-checkbox ${className}`}>
      <div className='w-full checkbox flex items-center px-6 min-h-[68px] relative overflow-hidden bg-white border-2 border-gray-300 rounded-lg input-item'>
        <div className={`checkbox-container ${isCheck ? '-checked' : ''}`} onClick={() => setIsCheck(!isCheck)}>
          <input type='checkbox' className='checkbox-item' {...props} id='acceptPolicy' />
          <svg className='checkbox-svg' preserveAspectRatio='none' viewBox='0 0 100 100'>
            <rect className='checkbox-border' rx='15%'></rect>
            <rect className='checkbox-border -active' rx='15%'></rect>
            <path className='checkbox-check' d='M 89.5 13 L 46 71 L 28 54'></path>
          </svg>
        </div>
        <label className='w-fit p-4' htmlFor='acceptPolicy'>
          <p className='text-sm text-[#0F0F0F]'>
            Ich akzeptiere die{' '}
            <a rel='noreferrer' href='https://mkg-uniqum.de/datenschutz/' target='_blank' className='underline'>
              Datenschutzbestimmungen
            </a>
          </p>
        </label>
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  className: string
};
