import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from 'utils';

import { SURVEY_FORM_BASE_URL } from './configs';
import { handleGenerateSubmissionDetail } from './utils';

export const useGetSubmissionById = () => {
  const { submissionId } = useParams();
  const { isValidating, data, mutate, error } = useSWR(`${SURVEY_FORM_BASE_URL}/get-submission-by-id/${submissionId}`, fetcher);

  return {
    errorMessage: error,
    isLoading: isValidating,
    mutate,
    data: data?.data || data?.data?.length ? handleGenerateSubmissionDetail(data.data[0] || data.data) : null
  };
};
