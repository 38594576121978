export const handleGenerateRegistration = (registration) => ({
  id: registration._id,
  anrede: registration.anrede,
  titel: registration.titel,
  practiceName: registration.practiceName,
  firstNameOfParticipant: registration.firstNameOfParticipant,
  surnameOfParticipant: registration.surnameOfParticipant,
  email: registration.email,
  phoneNumber: registration.phoneNumber,
  status: registration.status,
  createdAt: registration.createdAt,
  updatedAt: registration.updatedAt,
  updatedBy:
    registration.updatedBy?.length > 0
      ? {
          id: registration.updatedBy[0]._id,
          fullName: registration.updatedBy[0].fullName,
          email: registration.updatedBy[0].email,
          username: registration.updatedBy[0].username
        }
      : null
});
