import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { fetcher } from 'utils';

import { SURVEY_FORM_BASE_URL } from './configs';
import { handleGenerateSubmissionDetail } from './utils';

const getAllSubmissionBaseUrl = `${SURVEY_FORM_BASE_URL}/get-all-submission`;

export const useMutateGetSubmission = () => {
  const { mutate } = useSWRConfig();

  return useCallback(() => {
    mutate(getAllSubmissionBaseUrl);
  }, [mutate, getAllSubmissionBaseUrl]);
};

export const useGetAllSubmission = () => {
  const { isValidating, error, mutate, data } = useSWR(getAllSubmissionBaseUrl, fetcher);

  return {
    errorMessage: error,
    isLoading: isValidating,
    mutate,
    data: data?.data?.length ? data.data.map((submission) => handleGenerateSubmissionDetail(submission)) : []
  };
};
