import { any } from 'prop-types';
import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthService } from 'services';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (auth.access) {
      window.localStorage.setItem('access', JSON.stringify(auth.access));
    }
  }, [auth]);

  useEffect(() => {
    (async () => {
      const accessTokenStorage = window.localStorage.getItem('access');

      if ((accessTokenStorage || auth.access) && !auth.userInfo) {
        setIsLoading(true);
        try {
          const getAuthResponse = await getAuthService();

          if (getAuthResponse.data) {
            setAuth((prevState) => ({ ...prevState, userInfo: getAuthResponse.data }));
          } else {
            window.localStorage.removeItem('access');
          }
        } catch (error) {
          window.localStorage.removeItem('access');

          navigate('/login');
        }

        setIsLoading(false);
      }
    })();
  }, [auth]);

  return <AuthContext.Provider value={{ auth, setAuth, isLoading }}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: any
};
