import axios from 'axios';

// eslint-disable-next-line no-undef
export const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://uniqum-server.vercel.app';

const instance = axios.create({
  baseURL: baseURL,
  headers: { 'Content-Type': 'application/json' }
});

instance.interceptors.request.use((config) => {
  const access = JSON.parse(window.localStorage.getItem('access'));

  if (access) {
    const expireDate = new Date(access.expire);
    const currentDate = new Date();

    if (currentDate > expireDate) {
      window.localStorage.removeItem('access');
    }

    config.headers.accessToken = access.token.toString();
  }

  return config;
});

export default instance;
