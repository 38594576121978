import api from '../utils/api';

const LANDING_PAGE_BUILDER_BASE_URL = '/landing-page';

const handleGenerateLandingPageData = (landingPageData) => ({
  id: landingPageData._id,
  landingPageName: landingPageData.landingPageName,
  landingSlug: landingPageData.landingSlug,
  title: landingPageData.title,
  imageUrl: landingPageData.imageUrl,
  buttonLabel: landingPageData.buttonLabel,
  urlToLinkTo: landingPageData.urlToLinkTo,
  descriptions: landingPageData.descriptions,
  status: landingPageData.status,
  createdAt: landingPageData.createdAt,
  updatedAt: landingPageData.updatedAt
});

export const getAllLandingPageService = () => {
  return api.get(`${LANDING_PAGE_BUILDER_BASE_URL}/get-all`).then((response) => {
    if (response.status === 200 || response.data) {
      return response.data.map((landingPage) => handleGenerateLandingPageData(landingPage));
    }
  });
};

export const createLandingPageService = (data) => {
  const { landingPageName, title, imageUrl, buttonLabel, urlToLinkTo, descriptions } = data;

  return api.post(`${LANDING_PAGE_BUILDER_BASE_URL}/create`, {
    landingPageName: landingPageName,
    title: title,
    imageUrl: imageUrl,
    buttonLabel: buttonLabel,
    urlToLinkTo: urlToLinkTo,
    descriptions: descriptions
  });
};

export const getLandingPageBySlugService = (landingSlug) => {
  return api.get(`${LANDING_PAGE_BUILDER_BASE_URL}/get-by-slug/${landingSlug}`).then((response) => {
    return handleGenerateLandingPageData(response.data);
  });
};

export const updateLandingPage = (updateData) => {
  const { landingPageId, landingPageName, title, imageUrl, buttonLabel, urlToLinkTo, descriptions, status } = updateData;

  return api.patch(`${LANDING_PAGE_BUILDER_BASE_URL}/update-landing-page`, {
    landingPageId: landingPageId,
    landingPageName: landingPageName,
    title: title,
    imageUrl: imageUrl,
    buttonLabel: buttonLabel,
    urlToLinkTo: urlToLinkTo,
    descriptions: descriptions,
    status: status
  });
};
