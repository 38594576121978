import {
  ANSWER_QUESTION_1_KEY,
  ANSWER_QUESTION_2_KEY,
  SURVEY_FORM_ANSWER_KEY,
  SURVEY_FORM_ROOT_PATH,
  SURVEY_PROGRESS_PATH
} from 'pages/surveyForm/configs';
import { any } from 'prop-types';
import { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const SurveyFormContext = createContext({});

export const SurveyFormProvider = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const initialState = {
    [ANSWER_QUESTION_1_KEY]: '',
    [ANSWER_QUESTION_2_KEY]: ''
  };
  const [surveyFormData, setSurveyFormData] = useState(initialState);

  useEffect(() => {
    const indexPathnameInArray = SURVEY_PROGRESS_PATH.indexOf(pathname.replace(`${SURVEY_FORM_ROOT_PATH}/`, ''));
    const pathnameInFrontOfCurrentPath = SURVEY_PROGRESS_PATH[indexPathnameInArray - 1];
    if (!surveyFormData?.[SURVEY_FORM_ANSWER_KEY[pathnameInFrontOfCurrentPath]]) {
      navigate(`${SURVEY_FORM_ROOT_PATH}/${SURVEY_PROGRESS_PATH[0]}`);
    }
  }, [surveyFormData]);

  return <SurveyFormContext.Provider value={{ surveyFormData, setSurveyFormData }}>{children}</SurveyFormContext.Provider>;
};

SurveyFormProvider.propTypes = {
  children: any
};
