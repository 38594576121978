import MainLayout from 'components/mainLayout';
import { SWRConfig } from 'swr';
import { fetcher } from 'utils';

function App() {
  return (
    <SWRConfig
      value={{
        refreshInterval: 0,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        revalidateIfStale: false,
        revalidateOnReconnect: false,
        fetcher: fetcher
      }}
    >
      <MainLayout />
    </SWRConfig>
  );
}

export default App;
