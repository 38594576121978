import NavigatePageBtn from 'components/navigatePageBtn';
import { useSurveyForm } from 'hooks';
import { QuestionTitle } from 'pages/surveyForm/components';

import { ANSWER_QUESTION_2_KEY, SURVEY_FORM_ROOT_PATH, SURVEY_PROGRESS_PATH } from '../configs';

const ANSWER_OPTIONS = ['Vollzeit', 'Teilzeit'];

const Question2 = () => {
  const { surveyFormData, setSurveyFormData } = useSurveyForm();

  const handleSelectAnswer = (answer) => {
    setSurveyFormData((prevState) => ({ ...prevState, [ANSWER_QUESTION_2_KEY]: answer }));
  };

  return (
    <div className='w-full'>
      <QuestionTitle title={'Wie sieht es zeitlich bei Dir aus?'} />
      <div className='w-full space-y-6 md:space-y-8'>
        <div data-aos='fade-up' className='w-full grid grid-cols-1 gap-4 md:grid-cols-2'>
          {ANSWER_OPTIONS.map((answer) => (
            <div
              onClick={() => handleSelectAnswer(answer)}
              key={answer}
              className={`rounded-lg p-4 bg-gunmetal shadow-lighter-shadow text-cultured text-base min-h-64px flex items-center cursor-pointer hover:bg-gunmetal-dark transition-all duration-100 ease-[cubic-bezier(0.16,1,0.3,1)] active:scale-90 ${
                surveyFormData?.[ANSWER_QUESTION_2_KEY] === answer ? 'bg-gunmetal-dark' : ''
              }`}
            >
              <p>{answer}</p>
            </div>
          ))}
        </div>
        <div className='w-full'>
          <NavigatePageBtn
            disabled={!surveyFormData?.[ANSWER_QUESTION_2_KEY]}
            buttonLabel='Wie kontaktieren wir dich?'
            className='!py-0'
            subContent='Bewerben ohne Anschreiben & Lebenslauf'
            pageUrl={`${SURVEY_FORM_ROOT_PATH}/${SURVEY_PROGRESS_PATH[2]}`}
          />
        </div>
      </div>
    </div>
  );
};

export default Question2;
