import dayjs from 'dayjs';
import { mergeDateTimeToString } from 'utils';

export const sortUpcomingDate = (scheduleData) => {
  const scheduleDataTemp = [
    ...scheduleData.map((scheduleItem) => ({
      ...scheduleItem,
      timeToPresent: dayjs(dayjs(mergeDateTimeToString(scheduleItem.scheduleDate, scheduleItem.scheduleTime))).diff(dayjs())
    }))
  ];

  const upComingScheduleData = [...scheduleDataTemp.filter((scheduleItem) => scheduleItem.timeToPresent > 0)].sort(
    (a, b) => a.timeToPresent - b.timeToPresent
  );

  const pastScheduleData = [...scheduleDataTemp.filter((scheduleItem) => scheduleItem.timeToPresent < 0)].sort(
    (a, b) => a.timeToPresent - b.timeToPresent
  );

  return upComingScheduleData.concat(pastScheduleData);
};

export const handleGenerateScheduleDataObject = (data) => ({
  scheduleId: data._id,
  // scheduleName: data.scheduleName,
  scheduleTime: data.scheduleTime,
  scheduleDate: data.scheduleDate,
  location: data.location,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt,
  isPast: data.timeToPresent < 0,
  cronJobId: data.cronJobId || '',
  sentTo: data.sentTo.length
    ? data.sentTo.map((receiverItem) => ({
        id: receiverItem._id,
        email: receiverItem.email,
        fullName: receiverItem.fullName,
        createdAt: receiverItem.createdAt
      }))
    : []
});
