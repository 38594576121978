import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { SEMINAR_REGISTRATION_BASE_URL } from './configs';
import { handleGenerateRegistration } from './utils';

export const useGetSeminarById = () => {
  const { registrationId } = useParams();
  const { data, mutate, error, isValidating } = useSWR(`${SEMINAR_REGISTRATION_BASE_URL}/get-registration-by-id/${registrationId}`);

  return {
    errorMessage: error,
    isLoading: isValidating,
    mutate,
    data: data?.data ? handleGenerateRegistration(data.data) : null
  };
};
