export const handleGenerateSentMeetingScheduleEmailObject = (data) => ({
  emailId: data._id || '',
  receiverEmail: data.email || '',
  receiverFullName: data.fullName,
  location: data.location || '',
  createdAt: data.createdAt || '',
  submissionData: {
    submissionId: data.submissionData._id || '',
    fullName: data.submissionData.fullName || '',
    email: data.submissionData.email || '',
    status: data.submissionData.status || 0
  },
  scheduleData: {
    scheduleId: data.scheduleData._id || '',
    scheduleName: data.scheduleData.scheduleName || '',
    scheduleDate: data.scheduleData.scheduleDate || '',
    scheduleTime: data.scheduleData.scheduleTime || ''
  }
});
