import dayjs from 'dayjs';

export const dateStringFormat = 'YYYY/MM/DD';

export const timeStringFormat = 'HH:mm';

export const mergeDateTimeToString = (date, time) => `${date} ${time}`;

export const formatDate = (date) => {
  const dateConverted = dayjs(date).format(dateStringFormat);
  const timeConverted = dayjs(date).format(timeStringFormat);

  return mergeDateTimeToString(dateConverted, timeConverted);
};
