import { useSurveyForm } from 'hooks';
import { QuestionTitle } from 'pages/surveyForm/components';
import { useNavigate } from 'react-router-dom';

import {
  ANSWER_QUESTION_1_KEY,
  ANSWER_QUESTION_2_KEY,
  SURVEY_FORM_ROOT_PATH,
  SURVEY_PROGRESS_PATH,
  USER_SKIP_ANSWER_THE_FORM_QUESTION
} from '../configs';

const ANSWER_OPTIONS = [
  // 'ZFA für die Abrechnung (m/w/d)',
  'ZFA für die Behandlungsassistenz (m/w/d)',
  'ZFA für die Rezeption (m/w/d)',
  // 'ZFA für die Verwaltung (m/w/d)',
  'Ausbildung zur ZFA (m/w/d)'
];

const Question1 = () => {
  const navigate = useNavigate();
  const { surveyFormData, setSurveyFormData } = useSurveyForm();

  const handleSelectAnswer = (answer) => {
    if (ANSWER_OPTIONS.indexOf(answer) === ANSWER_OPTIONS.length - 1) {
      setSurveyFormData((prevState) => ({
        ...prevState,
        [ANSWER_QUESTION_1_KEY]: USER_SKIP_ANSWER_THE_FORM_QUESTION,
        [ANSWER_QUESTION_2_KEY]: USER_SKIP_ANSWER_THE_FORM_QUESTION
      }));

      navigate(`${SURVEY_FORM_ROOT_PATH}/${SURVEY_PROGRESS_PATH[2]}`);
    } else {
      setSurveyFormData((prevState) => ({ ...prevState, [ANSWER_QUESTION_1_KEY]: answer }));

      navigate(`${SURVEY_FORM_ROOT_PATH}/${SURVEY_PROGRESS_PATH[1]}`);
    }
  };

  return (
    <div className='w-full'>
      <QuestionTitle title={'Für welche Stelle interessierst Du dich?'} />
      <div className='w-full space-y-6 md:space-y-8'>
        <div data-aos='fade-up' className='w-full grid grid-cols-1 gap-4 md:grid-cols-2'>
          {ANSWER_OPTIONS.map((answer) => (
            <div
              onClick={() => handleSelectAnswer(answer)}
              key={answer}
              className={`rounded-lg p-4 bg-gunmetal shadow-lighter-shadow text-cultured text-base min-h-64px flex items-center cursor-pointer hover:bg-gunmetal-dark transition-all duration-100 ease-[cubic-bezier(0.16,1,0.3,1)] active:scale-90 ${
                surveyFormData?.[ANSWER_QUESTION_1_KEY] === answer ? 'bg-gunmetal-dark' : ''
              }`}
            >
              <p>{answer}</p>
            </div>
          ))}
        </div>
        <div data-aos='fade-up' className='w-full'>
          <h6 className='font-bold text-center text-lg sm:text-xl'>
            Wir zeigen dir wie alles funktioniert <span className='font-normal italic'>“Bei uns kommst Du schnell in deine Wunschposition”</span>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Question1;
