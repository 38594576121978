import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { fetcher } from 'utils';

import { SEMINAR_REGISTRATION_BASE_URL } from './configs';
import { handleGenerateRegistration } from './utils';

const getSeminarBaseUrl = `${SEMINAR_REGISTRATION_BASE_URL}/get-all`;

export const useMutateGetSeminar = () => {
  const { mutate } = useSWRConfig();

  return useCallback(() => {
    mutate(getSeminarBaseUrl);
  }, [mutate]);
};

export const useGetAllSeminar = () => {
  const { data, isValidating, error, mutate } = useSWR(getSeminarBaseUrl, fetcher);

  return {
    errorMessage: error,
    isLoading: isValidating,
    mutate,
    data: data?.data || data?.data?.length ? [...data.data.map((item) => handleGenerateRegistration(item))] : []
  };
};
