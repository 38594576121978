import { string } from 'prop-types';

const Title = ({ title, className = '' }) => {
  return (
    <div data-aos='fade-up' className={`py-8 text-2xl sm:text-4xl font-bold text-center ${className}`}>
      <h1 className='lg:px-16'>{title}</h1>
    </div>
  );
};

Title.propTypes = {
  title: string,
  className: string
};

export default Title;
