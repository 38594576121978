import { useAuth } from 'hooks';
import LandingPageFrame from 'pages/clientPageRoot/landingPageFrame';
import { useFetchClientRoutes } from 'pages/clientPageRoot/useFetchClientRoutes';
import React, { lazy } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import Loading from '../loading';
import { routes } from './routes';

const ClientPageRoot = lazy(() => import('pages/clientPageRoot'));

const MainLayout = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const { landingPageData, isFetchingClientRoutes } = useFetchClientRoutes();

  const handleGenerateRoute = (isPrivate, role, element) => {
    const accessStorage = window.localStorage.getItem('access');
    if (isPrivate) {
      return auth.access || accessStorage ? element : <Navigate to='/login' state={{ from: location }} replace />;
    }

    return element;
  };

  return (
    <Routes>
      <Route path='/' element={!isFetchingClientRoutes ? <ClientPageRoot /> : <Loading />}>
        {landingPageData.map((page, pageIndex) => (
          <Route
            index={pageIndex === 0}
            key={pageIndex === 0 ? '' : page.landingSlug}
            path={pageIndex === 0 ? '' : page.landingSlug}
            element={page.status ? <LandingPageFrame localPageData={page} landingPageSlug={page.landingSlug} /> : <Navigate to={'/page-not-found'} />}
          />
        ))}
      </Route>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={!isFetchingClientRoutes ? handleGenerateRoute(route.isPrivate, route.role, route.element) : <Loading />}
        >
          {route.children.length > 0 ? (
            route.children.map((childRoute) => <Route key={childRoute.path} path={childRoute.path} element={childRoute.element} />)
          ) : (
            <></>
          )}
        </Route>
      ))}
    </Routes>
  );
};

export default MainLayout;
