import api from 'utils/api';

const AUTH_BASE_URL = '/account';

export const loginService = (data) => {
  return api.post(`${AUTH_BASE_URL}/login`, {
    username: data.username,
    password: data.password
  });
};

export const getAuthService = () => {
  return api.get(`${AUTH_BASE_URL}/get-account-info`);
};

export const resetPasswordService = (data) => {
  return api.patch(`${AUTH_BASE_URL}/reset-password`, {
    username: data.username,
    email: data.email,
    password: data.password,
    confirmPassword: data.confirmPassword
  });
};

export const getAllUserInfoService = () => {
  return api
    .get(`${AUTH_BASE_URL}/get-all-account-info`)
    .then((response) => {
      if (response.status === 200 || response.statusText === 'OK' || response.data.length > 0) {
        const { data } = response;

        return data.map((userInfo) => ({
          id: userInfo._id,
          email: userInfo.email,
          username: userInfo.username,
          fullName: userInfo.fullName,
          status: userInfo.status,
          createdAt: userInfo.createdAt,
          updatedAt: userInfo.updatedAt
        }));
      }
    })
    .catch((error) => error);
};

export const createNewAccountService = (data) => {
  return api.post(`${AUTH_BASE_URL}/create`, {
    username: data.username,
    password: data.password,
    fullName: data.fullName,
    email: data.email
  });
};
