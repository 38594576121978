import dayjs from 'dayjs';

import { mergeDateTimeToString } from '../../utils';

const sortScheduleData = (scheduleData) => {
  const scheduleDataTemp = [
    ...scheduleData.map((scheduleItem) => ({
      scheduleId: scheduleItem._id,
      receiverEmail: scheduleItem.email,
      receiverFullName: scheduleItem.fullName,
      sentInviteAt: scheduleItem.createdAt,
      scheduleInfo: scheduleItem.scheduleInfo.length
        ? {
            // scheduleName: scheduleItem.scheduleInfo[0].scheduleName,
            scheduleDate: scheduleItem.scheduleInfo[0].scheduleDate,
            scheduleTime: scheduleItem.scheduleInfo[0].scheduleTime,
            location: scheduleItem.scheduleInfo[0].location,
            timeToPresent: dayjs(
              dayjs(mergeDateTimeToString(scheduleItem.scheduleInfo[0].scheduleDate, scheduleItem.scheduleInfo[0].scheduleTime))
            ).diff(dayjs())
          }
        : null
    }))
  ];

  const upComingScheduleData = [
    ...scheduleDataTemp
      .filter((scheduleItem) => scheduleItem.scheduleInfo?.timeToPresent > 0)
      .sort((a, b) => a.scheduleInfo?.timeToPresent - b.scheduleInfo?.timeToPresent)
  ];

  const pastScheduleData = [...scheduleDataTemp.filter((scheduleItem) => scheduleItem.scheduleInfo?.timeToPresent < 0)].sort(
    (a, b) => a.scheduleInfo?.timeToPresent - b.scheduleInfo?.timeToPresent
  );

  return upComingScheduleData.concat(pastScheduleData);
};

export const handleGenerateSubmissionDetail = (submissionData) => ({
  id: submissionData._id,
  fullName: submissionData.fullName,
  email: submissionData.email,
  phoneNumber: submissionData.phoneNumber,
  submissionFrom: submissionData.submissionFrom,
  timeToContact: submissionData.timeToContact,
  acceptPolicy: submissionData.acceptPolicy,
  positionInterested: submissionData.positionInterested,
  highestProfessionalQualification: submissionData.highestProfessionalQualification,
  certificateValid: submissionData.certificateValid,
  moreInterestedIn: submissionData.moreInterestedIn,
  timeToStart: submissionData.timeToStart,
  bestReachable: submissionData.bestReachable,
  status: submissionData.status,
  scheduleData: submissionData.scheduleData ? sortScheduleData(submissionData.scheduleData) : [],
  createdAt: submissionData.createdAt,
  createdBy:
    submissionData.createdBy?.length > 0
      ? {
          id: submissionData.createdBy[0]._id,
          fullName: submissionData.createdBy[0].fullName,
          email: submissionData.createdBy[0].email,
          username: submissionData.createdBy[0].username
        }
      : null,
  updatedAt: submissionData.updatedAt,
  updatedBy:
    submissionData.updatedBy?.length > 0
      ? {
          id: submissionData.updatedBy[0]._id,
          fullName: submissionData.updatedBy[0].fullName,
          email: submissionData.updatedBy[0].email,
          username: submissionData.updatedBy[0].username
        }
      : null
});
