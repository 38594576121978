import { useCallback } from 'react';
import api from 'utils/api';

import { SURVEY_FORM_BASE_URL } from './configs';
import { useMutateGetSubmission } from './useGetAllSubmission';

export const useAddNewSubmission = () => {
  const mutateCase = useMutateGetSubmission();

  return useCallback(
    async (submissionData) => {
      const { email, fullName, phoneNumber, submissionFrom, timeToContact, acceptPolicy, positionInterested, moreInterestedIn } = submissionData;

      const submissionParams = {
        email: email,
        fullName: fullName,
        phoneNumber: phoneNumber
      };
      if (String(submissionFrom)) submissionParams.submissionFrom = submissionFrom;
      if (String(timeToContact)) submissionParams.timeToContact = timeToContact;
      if (String(acceptPolicy)) submissionParams.acceptPolicy = acceptPolicy;
      if (String(positionInterested)) submissionParams.positionInterested = positionInterested;
      if (String(moreInterestedIn)) submissionParams.moreInterestedIn = moreInterestedIn;
      const addNewSubmissionResponse = await api.post(`${SURVEY_FORM_BASE_URL}/submit-form`, submissionParams);

      mutateCase();

      return addNewSubmissionResponse;
    },
    [mutateCase]
  );
};
