import { string } from 'prop-types';
import { forwardRef } from 'react';

const ImageResponsive = forwardRef(({ src, alt, className = '', ...props }, ref) => {
  return <img ref={ref} {...props} src={src} alt={alt} className={`absolute left-0 top-0 w-full h-full object-cover ${className}`} />;
});

ImageResponsive.displayName = 'ImageResponsive';

ImageResponsive.propTypes = {
  src: string,
  alt: string,
  className: string
};

export default ImageResponsive;
