import { any, string } from 'prop-types';

const ContainerLayout = ({ className = '', children }) => {
  return <div className={`container mx-auto px-6 pb-8 lg:px-0 ${className}`}>{children}</div>;
};

ContainerLayout.propTypes = {
  children: any,
  className: string
};

export default ContainerLayout;
