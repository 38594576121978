import { string } from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../button';

const NavigatePageBtn = ({ buttonLabel, pageUrl, subContent, className = '', ...props }) => {
  const navigate = useNavigate();

  return (
    <div data-aos='fade-up' className={`w-full text-center pt-4 pb-2 space-y-3 sm:space-y-5 sm:px-8 ${className}`}>
      <Button
        {...props}
        className='banner-section__btn'
        label={
          <>
            <span>{buttonLabel}</span>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M17 8L21 12M21 12L17 16M21 12L3 12' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
          </>
        }
        onClick={() => navigate(pageUrl)}
      />
      {subContent ? <p className='text-center italic'>{subContent}</p> : <></>}
    </div>
  );
};

NavigatePageBtn.propTypes = {
  buttonLabel: string,
  pageUrl: string,
  subContent: string,
  className: string
};

export default NavigatePageBtn;
