import Button from 'components/button';
import Toast from 'components/toast';
import { useFormik } from 'formik';
import { useSurveyForm } from 'hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAddNewSubmission } from 'services';
import { handleValidateInputStyle } from 'utils';
import * as yup from 'yup';

import { Checkbox, Input, QuestionTitle } from '../components';
import { SURVEY_FORM_ROOT_PATH, SURVEY_PROGRESS_PATH } from '../configs';

const ContactInfoForm = () => {
  const navigate = useNavigate();
  const addNewSubmissionService = useAddNewSubmission();
  const { surveyFormData } = useSurveyForm();
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      phoneNumber: '',
      timeToContact: '',
      acceptPolicy: false
    },
    validationSchema: yup.object().shape({
      fullName: yup.string().required(),
      email: yup.string().email().required(),
      phoneNumber: yup.number().required(),
      timeToContact: yup.string().required(),
      acceptPolicy: yup.bool().oneOf([true])
    }),
    onSubmit: async (values) => {
      const { fullName, email, phoneNumber, timeToContact, acceptPolicy } = values;

      setIsLoading(true);

      const surveyFormDataInput = {
        ...surveyFormData,
        fullName: fullName,
        email: email,
        phoneNumber: phoneNumber,
        timeToContact: timeToContact,
        acceptPolicy: acceptPolicy
      };

      try {
        const submitSurveyFormResponse = await addNewSubmissionService(surveyFormDataInput);
        if (submitSurveyFormResponse.status === 201 || submitSurveyFormResponse.data._id) {
          navigate(`${SURVEY_FORM_ROOT_PATH}/${SURVEY_PROGRESS_PATH[3]}`);
        }
      } catch (error) {
        setSubmitError(error.message);
      }

      setIsLoading(false);
    }
  });

  return (
    <div className='w-full survey-form__contact-info'>
      <QuestionTitle title='Wie können wir Dich kontaktieren?' />
      {submitError ? <Toast type={'danger'} message={submitError} resetErrorMessage={setSubmitError} /> : <></>}
      <div className='w-full pb-4'>
        <div data-aos='fade-up' className='mx-auto max-w-md sm:max-w-xl w-full'>
          <form onSubmit={formik.handleSubmit} className='space-y-2 sm:mt-2 survey-form__contact-info__form'>
            <Input
              inputIcon={
                <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M10 9C10.7956 9 11.5587 8.68393 12.1213 8.12132C12.6839 7.55871 13 6.79565 13 6C13 5.20435 12.6839 4.44129 12.1213 3.87868C11.5587 3.31607 10.7956 3 10 3C9.20435 3 8.44129 3.31607 7.87868 3.87868C7.31607 4.44129 7 5.20435 7 6C7 6.79565 7.31607 7.55871 7.87868 8.12132C8.44129 8.68393 9.20435 9 10 9ZM3 18C3 17.0807 3.18106 16.1705 3.53284 15.3212C3.88463 14.4719 4.40024 13.7003 5.05025 13.0503C5.70026 12.4002 6.47194 11.8846 7.32122 11.5328C8.1705 11.1811 9.08075 11 10 11C10.9193 11 11.8295 11.1811 12.6788 11.5328C13.5281 11.8846 14.2997 12.4002 14.9497 13.0503C15.5998 13.7003 16.1154 14.4719 16.4672 15.3212C16.8189 16.1705 17 17.0807 17 18H3Z'
                    fill='#10A9C3'
                  />
                </svg>
              }
              type={'text'}
              placeholder={'Dein Vor- und Nachname'}
              name='fullName'
              value={formik.values.fullName}
              onChange={formik.handleChange}
              className={handleValidateInputStyle(formik, 'fullName')}
            />
            <Input
              inputIcon={
                <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M2.00293 5.884L9.99993 9.882L17.9969 5.884C17.9673 5.37444 17.744 4.89549 17.3728 4.54523C17.0015 4.19497 16.5103 3.99991 15.9999 4H3.99993C3.48951 3.99991 2.99838 4.19497 2.62711 4.54523C2.25584 4.89549 2.03253 5.37444 2.00293 5.884Z'
                    fill='#10A9C3'
                  />
                  <path
                    d='M18 8.118L10 12.118L2 8.118V14C2 14.5304 2.21071 15.0391 2.58579 15.4142C2.96086 15.7893 3.46957 16 4 16H16C16.5304 16 17.0391 15.7893 17.4142 15.4142C17.7893 15.0391 18 14.5304 18 14V8.118Z'
                    fill='#10A9C3'
                  />
                </svg>
              }
              type={'email'}
              placeholder={'Deine E-Mail Adresse'}
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              className={handleValidateInputStyle(formik, 'email')}
            />
            <Input
              inputIcon={
                <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H5.153C5.38971 2.00011 5.6187 2.08418 5.79924 2.23726C5.97979 2.39034 6.10018 2.6025 6.139 2.836L6.879 7.271C6.91436 7.48222 6.88097 7.69921 6.78376 7.89003C6.68655 8.08085 6.53065 8.23543 6.339 8.331L4.791 9.104C5.34611 10.4797 6.17283 11.7293 7.22178 12.7782C8.27072 13.8272 9.52035 14.6539 10.896 15.209L11.67 13.661C11.7655 13.4695 11.9199 13.3138 12.1106 13.2166C12.3012 13.1194 12.5179 13.0859 12.729 13.121L17.164 13.861C17.3975 13.8998 17.6097 14.0202 17.7627 14.2008C17.9158 14.3813 17.9999 14.6103 18 14.847V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H15C7.82 18 2 12.18 2 5V3Z'
                    fill='#10A9C3'
                  />
                </svg>
              }
              type={'text'}
              placeholder={'Deine Telefonnummer'}
              name='phoneNumber'
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              className={handleValidateInputStyle(formik, 'phoneNumber')}
            />
            <Input
              inputIcon={
                <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM11 6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5C9.73478 5 9.48043 5.10536 9.29289 5.29289C9.10536 5.48043 9 5.73478 9 6V10C9.00006 10.2652 9.10545 10.5195 9.293 10.707L12.121 13.536C12.2139 13.6289 12.3242 13.7026 12.4456 13.7529C12.567 13.8032 12.6971 13.8291 12.8285 13.8291C12.9599 13.8291 13.09 13.8032 13.2114 13.7529C13.3328 13.7026 13.4431 13.6289 13.536 13.536C13.6289 13.4431 13.7026 13.3328 13.7529 13.2114C13.8032 13.09 13.8291 12.9599 13.8291 12.8285C13.8291 12.6971 13.8032 12.567 13.7529 12.4456C13.7026 12.3242 13.6289 12.2139 13.536 12.121L11 9.586V6Z'
                    fill='#10A9C3'
                  />
                </svg>
              }
              type={'text'}
              placeholder={'Wann bist du am besten erreichbar?'}
              name='timeToContact'
              value={formik.values.timeToContact}
              onChange={formik.handleChange}
              className={handleValidateInputStyle(formik, 'timeToContact')}
            />
            <Checkbox
              className={`${handleValidateInputStyle(formik, 'acceptPolicy')}`}
              name='acceptPolicy'
              value={formik.values.acceptPolicy}
              onChange={formik.handleChange}
            />
            <div className='!mt-8'>
              <Button type='submit' label={!isLoading ? 'Jetzt einfach bewerben' : 'Senden'} className='banner-section__btn' disabled={isLoading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoForm;
