import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import api from 'utils/api';

import { SEMINAR_REGISTRATION_BASE_URL } from './configs';
import { useMutateGetSeminar } from './useGetAllSeminar';
import { useGetSeminarById } from './useGetSeminarById';

export const useUpdateSeminar = () => {
  const { registrationId } = useParams();
  const { mutate } = useGetSeminarById();
  const mutateCaseAllData = useMutateGetSeminar();

  return useCallback(
    async (status) => {
      const updateSeminarResponse = await api.patch(`${SEMINAR_REGISTRATION_BASE_URL}/update-registration/${registrationId}`, {
        status: status
      });

      mutate();

      mutateCaseAllData();

      return updateSeminarResponse.data;
    },
    [mutate, registrationId, mutateCaseAllData]
  );
};
