import ContainerLayout from 'components/containerLayout';
import ImageLayout from 'components/imageLayout';
import ImageResponsive from 'components/imageResponsive';
import NavigatePageBtn from 'components/navigatePageBtn';
import SectionDetailGridLayout from 'components/sectionDetailGridLayout';
import SectionLayout from 'components/sectionLayout';
import Title from 'components/title';
import { arrayOf, bool, shape, string } from 'prop-types';
import React from 'react';
import { Navigate } from 'react-router-dom';

const LandingPageFrame = ({ localPageData }) => {
  return localPageData ? (
    <ContainerLayout>
      <SectionLayout className='banner-section'>
        <Title title={localPageData.title} />
        {localPageData?.descriptions.length ? <SectionDetailGridLayout data={localPageData.descriptions} isBoldText={true} /> : <></>}
        <ImageLayout>
          <div className='w-full flex justify-center items-center'>
            <div className='max-w-608px w-full'>
              <div className='w-full pb-ratio-608x456 relative rounded-lg overflow-hidden'>
                <ImageResponsive src={localPageData.imageUrl} alt={'banner-section-image'} />
              </div>
            </div>
          </div>
        </ImageLayout>
        <NavigatePageBtn pageUrl={localPageData.urlToLinkTo} buttonLabel={localPageData.buttonLabel} />
      </SectionLayout>
    </ContainerLayout>
  ) : (
    <Navigate to={'/page-not-found'} />
  );
};

LandingPageFrame.propTypes = {
  localPageData: shape({
    id: string,
    landingPageName: string,
    landingSlug: string,
    title: string,
    imageUrl: string,
    buttonLabel: string,
    urlToLinkTo: string,
    descriptions: arrayOf(string),
    status: bool,
    createdAt: string,
    updatedAt: string
  }),
  landingPageSlug: string
};

export default LandingPageFrame;
